import { Video } from '@/types/video'

export default function getVideoLength(
    video: Partial<Pick<Video, 'duration'>>
): number | undefined {
    if (!video.duration) {
        return undefined
    }

    const [seconds, minutes = 0, hours = 0] = video.duration.split(':').reverse().map(Number)
    const length = seconds + 60 * minutes + 60 * 60 * hours
    return Number.isNaN(length) ? undefined : length
}
