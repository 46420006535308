type GetPrerollsCountProps = {
    duration?: number
    isLivestream?: boolean
}

export const getPrerollsCount = ({ duration, isLivestream = false }: GetPrerollsCountProps) => {
    if (isLivestream) return 3
    if (!duration) return 3
    const min = 60
    if (duration < 1 * min) {
        return 1
    } else if (duration >= 1 * min && duration < 2 * min) {
        return 2
    } else if (duration >= 2 * min && duration < 5 * min) {
        return 3
    } else if (duration >= 5 * min && duration < 8 * min) {
        return 5
    }
    return 8
}
