import { AgfSdk, AgfConfig, AgfContent, AgfInstance } from '@/types/agf'
import { IJWPlayer, IJWEvent, IJWAdEvent } from '@/types/jwplayer'
import { Type } from '@/types/video'

const LIVESTREAM_24_7_TITLE = '24_7 Livestream'

/**
 * Add AGF/Nielsen tracking to jwplayers.
 * Nielsen combines views in browsers (this integration here), with
 * views in apps and tv sets; so our market reach increases.
 *
 * Fair warning: we are using a hosted jwplayer version that just
 * automatically updates. The lifecycles you see in the tests send
 * events as observed for the current jwplayer (8.18.2) now
 * (2020-12-04). New versions might emit events in different order. This
 * happened in the past.
 *
 * See https://engineeringportal.nielsen.com/docs/DCR_Germany_Video_Browser_SDK
 * See https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference
 */

declare let window: {
    addEventListener: Function
    dataLayer?: Array<{ event: string }>
    NOLBUNDLE?: AgfSdk
    removeEventListener: Function
    S1_CMP_AGF?: boolean
    s1AgfConfig?: AgfConfig
}

interface Setup {
    contentMetadata: AgfContent
    player: IJWPlayer | undefined
    type: Type
}

interface Params {
    endTime?: Date | number | null
    format?: string | null
    ivwCode?: string
    length?: number
    player: IJWPlayer | undefined
    program?: string | null
    startTime?: Date | number | null
    title: string
    type: Type
    videoId?: string
}

const adTypes = {
    pre: 'preroll',
    mid: 'midroll',
    post: 'postroll',
}

const stream: {
    [key: string]: AgfContent['nol_c18']
} = {
    eventStream: 'p18,Y',
    liveStream: 'p18,Y',
    VoD: 'p18,N',
}

class AgfJw {
    private adIsPlaying = false
    private adLastPlayheadPositionChange: number | undefined = undefined
    private adLength = 0
    private adPlayheadPosition = 0

    private contentIsBuffering = false
    private contentIsPlaying = false
    private contentMetadata: AgfContent
    private contentMetadataSentInitially = false
    private contentPlayheadPosition = 0

    private sdk?: AgfInstance
    private config?: AgfConfig
    private player?: IJWPlayer
    private type: Setup['type'] = 'liveStream'

    private exit: Function | null

    /**
     * streams and VoD have to pass different seconds to the SDK
     */
    private getContentPosition() {
        return 'VoD' === this.type ? this.getPosition() : Math.floor(Date.now() / 1000)
    }

    private getPosition() {
        /* istanbul ignore next */
        if (!this.player || 'function' !== typeof this.player.getPosition) {
            return NaN
        }
        return Math.floor(this.player.getPosition())
    }

    private track(label: string, data: object | number) {
        if (!window.NOLBUNDLE || !window.s1AgfConfig) {
            return
        }

        /**
         * config comes from GTM, so it might arrive very late
         * make a hard clone of s1AgfConfig, easier to test
         */
        if (!this.config) {
            this.config = JSON.parse(JSON.stringify(window.s1AgfConfig)) as AgfConfig
        }

        /**
         * gfkId comes from GTM and GFK callback, so it might arrive
         * very very late
         */
        if (!this.contentMetadata.nol_c20 && this.config.gfkId) {
            this.contentMetadata.nol_c20 = `p20,${this.config.gfkId}`
        }

        /**
         * NOLBUNDLE comes from GTM, so it might arrive very late
         */
        if (!this.sdk) {
            this.sdk = window.NOLBUNDLE.nlsQ(this.config.apid, 'jw8', this.config.debug)
        }

        this.sdk.ggPM(label, data)

        if (this.config.debug) {
            // eslint-disable-next-line no-console
            console.debug('AgfJw', label, data)
        }
    }

    /**
     * if ads start buffering, player.getState will still be "playing", sigh
     * we shall track "stop"
     * in difference to content tracking we do not track "loadMetadata"
     * when resuming
     */
    private trackAdBuffering() {
        if (this.adIsPlaying) {
            if (
                undefined !== this.adLastPlayheadPositionChange &&
                Date.now() - this.adLastPlayheadPositionChange > 2000
            ) {
                // buffering
                this.track('stop', Math.min(this.adPlayheadPosition, this.adLength))
                this.adLastPlayheadPositionChange = undefined
            }
        }

        setTimeout(this.trackAdBuffering.bind(this), 1000)
    }

    private trackAdStop() {
        if (this.adIsPlaying) {
            this.track('stop', Math.min(this.adPlayheadPosition, this.adLength))
        }
        this.adIsPlaying = false
        this.adLastPlayheadPositionChange = undefined
        this.adLength = 0
        this.adPlayheadPosition = 0
    }

    // We have neither access to length nor position in onAdPlay,
    // therefore we initialize the ad tracking on adTime and keep track
    // ourselves :(
    // Keep in mind: this function is called more that once a second,
    // while an ad plays!
    private trackAdTime(event: IJWEvent) {
        const adEvent = event as IJWAdEvent
        this.adLength = Math.round(adEvent.duration || 0)
        this.contentIsPlaying = false
        const position = Math.floor(adEvent.position || 0)

        if (!this.adIsPlaying && 0 === position) {
            // send contentMetadata, if not yet done
            if (!this.contentMetadataSentInitially) {
                this.contentMetadataSentInitially = true
                this.track('loadMetadata', this.contentMetadata)
            }

            // send adMetadata, if not yet done
            const positionKey = adEvent.adposition || adEvent.adschedule?.offset || 'mid'
            const type = adTypes[positionKey]
            const assetid = `${adEvent.id}${type}${adEvent.sequence || ''}`
            this.track('loadMetadata', {
                type,
                nol_c17: `p17,${type}`,
                nol_c12: 'p12,Werbung',

                assetid,
                title: assetid,
                nol_c11: `p11,${assetid}`,

                length: this.adLength,
                nol_c8: `p8,${this.adLength}`,

                nol_c10: 'p10,unknown',
            })
        }

        this.adIsPlaying = true

        if (position && position > this.adPlayheadPosition) {
            this.adPlayheadPosition = position
            this.adLastPlayheadPositionChange = Date.now()
            this.track('setPlayheadPosition', position)
        }
    }

    /**
     * Track 'stop' if player starts buffering
     * Track 'loadMetadata' if player recovers from buffering
     */
    private trackContentBuffering() {
        if (
            this.contentIsPlaying &&
            !this.contentIsBuffering &&
            this.player?.getState() === 'buffering'
        ) {
            this.contentIsBuffering = true
            this.track('stop', this.getContentPosition())
        }

        if (
            this.contentIsPlaying &&
            this.contentIsBuffering &&
            this.player?.getState() === 'playing'
        ) {
            this.contentIsBuffering = false
            this.track('loadMetadata', this.contentMetadata)
        }

        setTimeout(this.trackContentBuffering.bind(this), 1000)
    }

    private trackContentLoadMetadata() {
        if (!this.contentIsPlaying) {
            this.track('loadMetadata', this.contentMetadata)
        }
        this.contentIsPlaying = true
    }

    /**
     * Track each second the player is playing content
     */
    private trackContentSetPlayheadPosition() {
        const position = this.getContentPosition()
        if (
            position &&
            this.contentIsPlaying &&
            position !== this.contentPlayheadPosition &&
            this.player?.getState() === 'playing' // vs buffering
        ) {
            this.contentPlayheadPosition = position
            this.track('setPlayheadPosition', position)
        }
        setTimeout(this.trackContentSetPlayheadPosition.bind(this), 1000)
    }

    private trackContentEnd() {
        if (this.contentIsPlaying) {
            this.track('end', this.getContentPosition())
        }
        this.contentIsPlaying = false
        this.contentMetadataSentInitially = false
        this.contentPlayheadPosition = 0
    }

    private trackContentStop() {
        if (this.contentIsPlaying) {
            this.track('stop', this.getContentPosition())
        }
        this.contentIsPlaying = false
    }

    private trackExit() {
        if (this.adIsPlaying) {
            const position = this.getPosition()
            this.track('stop', position)
            this.track('end', position)
        } else if (this.contentIsPlaying) {
            this.track('end', this.getContentPosition())
        }
        this.adIsPlaying = false
        this.contentIsPlaying = false
    }

    public init(): void {
        if (!this.player || 'function' !== typeof this.player.on) {
            return
        }

        // content
        this.player.on('complete', this.trackContentEnd.bind(this))
        this.player.on('error', this.trackContentStop.bind(this))
        this.player.on('pause', this.trackContentStop.bind(this))
        this.player.on('play', this.trackContentLoadMetadata.bind(this))
        this.trackContentSetPlayheadPosition()
        this.trackContentBuffering()

        // ads
        this.player.on('adComplete', this.trackAdStop.bind(this))
        this.player.on('adError', this.trackAdStop.bind(this))
        this.player.on('adPause', this.trackAdStop.bind(this))
        this.player.on('adTime', this.trackAdTime.bind(this))
        this.trackAdBuffering()

        // cleanup (important to AGf)
        window.addEventListener('beforeunload', this.exit)
        window.addEventListener('pagehide', this.exit)

        window.S1_CMP_AGF = true
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ event: 'cmp_agf' })
    }

    constructor({ player, type, contentMetadata }: Setup) {
        this.player = player
        this.type = type
        this.contentMetadata = contentMetadata
        this.exit = this.trackExit.bind(this)
    }
}

export default function attachAgfTracking({
    endTime,
    format,
    ivwCode,
    length,
    player,
    program,
    startTime,
    title,
    type,
    videoId,
}: Params): AgfJw | undefined {
    if (program) {
        title = [program, title].join(' | ')
    }

    if (type === 'liveStream') {
        length = 86400

        // don't show "Teleshopping"
        title = LIVESTREAM_24_7_TITLE
        program = LIVESTREAM_24_7_TITLE
    }

    length = length || Math.round((Number(endTime) - Number(startTime)) / 1000 || 90)

    title = title.substring(0, 255)
    program = (program || title).substring(0, 255)

    const webOnly = (() => {
        if (type === 'liveStream') {
            return false
        }
        if (type === 'eventStream') {
            return true
        }
        if (format) {
            return false
        }
        return true
    })()

    const nol_c2 = webOnly ? 'p2,Y' : 'p2,N'
    const nol_c19 = startTime ? `p19,${startTime}` : undefined

    /**
     * see interface AgfContent for comments on these properties
     */
    const agf = new AgfJw({
        player,
        type,
        contentMetadata: {
            assetid: videoId || 'unknown',
            length,
            program,
            title,
            type: 'content',

            nol_c0: 'p0,0',
            nol_c2,
            nol_c5: `p5,${location.href.split('?').shift()}`,
            nol_c7: `p7,${videoId || 'unknown'}`,
            nol_c8: `p8,${length}`,
            nol_c9: `p9,${program}`,
            nol_c10: 'p10,Sport1',
            nol_c12: 'p12,Content',
            nol_c15: format ? `p15,${format}` : undefined,
            nol_c16: ivwCode ? `p16,${ivwCode}` : undefined,
            nol_c18: stream[type],
            nol_c19,
        },
    })

    agf.init()

    return agf
}
