import { DefinitionType } from './types/Definition.type'
import { IJWSource } from 'src/@types/jwplayer'

const definitions = [
    {
        label: 'HD',
        url: '2',
    },
    {
        label: 'SD',
        url: '3',
    },
    {
        label: 'LD',
        url: '4',
    },
]

export const generateVideoSources = (url?: string): Array<IJWSource> => {
    const definitionText = '{definition}'
    const definitionString =
        url?.replace(
            /_[2-4]\.(mov|MOV|avi|AVI|wmv|WMV|flv|FLV|3gp|3GP|mp4|MP4|mpg|MPG)/,
            `_${definitionText}.$1`
        ) || ''
    if (definitionString.indexOf(definitionText) === -1) {
        return []
    }
    return definitions.map((definition: DefinitionType): IJWSource => {
        return {
            label: definition.label,
            file: definitionString.replace(definitionText, definition.url),
        }
    })
}
