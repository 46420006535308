import React, { FC, ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import Font from '@sport1/news-styleguide/Font'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import TVStreamTeaser from '@sport1/news-styleguide/TVStreamTeaser'
import { AdConfigDataProps } from '@sport1/types/web'
import dayjs from 'dayjs'
import Sport1Logo from '@sport1/news-styleguide/Sport1Logo'
import * as Sentry from '@sentry/nextjs'
import midrolls from '@/components/Ads/livestream/midrolls'
import JWPlayer from '@/components/Player/JWPlayer/JWPlayer'
import { transformImageUrlToSize } from '@/helpers/imageHelper'
import { generateVideoSources } from '@/helpers/videoHelper/generateVideoSources'
import { IJWPlayer } from '@/types/jwplayer'
import { Type } from '@/types/video'
import { mapTrackingData, TrackingData, useTracking } from '@/utils/tracking/TrackingProvider'
import attachAgfTracking from '@/utils/tracking/attachAgfTracking'
import { buildVideoZone } from '@/utils/ads/vastConfig'
import { sport1Regular } from '@/helpers/fonts'

type Props = {
    videoId: string
    label?: string
    videoUrl: string
    videoAds?: AdConfigDataProps
    trackingData?: TrackingData
    imageUrl: string
    isCardTeaser?: boolean
    title: string
    subTitle?: string
    hidePlayButton?: boolean
    type: Type
    startTime?: Date
    endTime?: Date
    testID?: string
    shouldAddMarginBottom?: boolean
}

const LivestreamPlayer: FC<Props> = ({
    videoUrl,
    videoAds,
    label,
    imageUrl,
    title,
    subTitle,
    videoId,
    trackingData,
    hidePlayButton,
    type,
    startTime,
    endTime,
    testID,
    shouldAddMarginBottom,
}) => {
    const jwPlayerInstance = useRef<IJWPlayer | undefined>()
    const containerRef = useRef<(ReactElement & HTMLDivElement) | null>(null)
    const [isVideoTouched, setVideoTouched] = useState<boolean>(false)
    const { trackInteraction } = useTracking()

    const jwPlayer = useMemo(() => {
        const mappedTrackingData = mapTrackingData(trackingData)
        return (
            <JWPlayer
                id={videoId}
                playlist={[
                    {
                        file: videoUrl,
                        metaid: videoId,
                        title,
                        sources: generateVideoSources(videoUrl),
                        onXhrOpen: (xhr, url) => {
                            xhr.onreadystatechange = () => {
                                if (
                                    xhr.readyState === 4 &&
                                    (xhr.status === 0 || xhr.status >= 400)
                                ) {
                                    trackInteraction({
                                        interaction_category: 'error_stream',
                                        interaction_label:
                                            `${xhr.statusText}; ${xhr.responseText}`.substring(
                                                0,
                                                254
                                            ),
                                        page_name: url,
                                        status_code: `${xhr.status}`,
                                    })

                                    Sentry.captureException(
                                        new Error(
                                            `JWPlayer XHR - StatusCode: ${xhr.status} for ${url} with following response: ${xhr.responseText}`
                                        )
                                    )
                                }
                            }
                        },
                    },
                    {
                        file: 'https://vod-int.sport1.de/15D081AB-3BF2-4231-8896-F26AEE1C3F0A/1-1080p.mp4',
                        metaid: '3B8DCACC-C43C-4214-A698-7ECF553739DF',
                        title,
                        sources: generateVideoSources(
                            'https://vod-int.sport1.de/15D081AB-3BF2-4231-8896-F26AEE1C3F0A/1-1080p.mp4'
                        ),
                    },
                ]}
                ad={{ ...videoAds, prerollEnabled: true } as AdConfigDataProps}
                callback={(player: IJWPlayer) => {
                    if (type === 'liveStream') {
                        midrolls(player, title, videoUrl, buildVideoZone(type), videoAds)
                    }

                    attachAgfTracking({
                        endTime,
                        ivwCode: mappedTrackingData.page_ivw_code,
                        player,
                        program: mappedTrackingData.agf_program_title,
                        startTime,
                        title,
                        type,
                        videoId,
                    })

                    jwPlayerInstance.current = player
                }}
                trackingData={mappedTrackingData}
                type={type}
                testID={testID}
            />
        )
    }, [
        endTime,
        startTime,
        testID,
        title,
        trackingData,
        trackInteraction,
        type,
        videoAds,
        videoId,
        videoUrl,
    ])

    const onPlay = useCallback(() => {
        setVideoTouched(true)
        jwPlayerInstance.current?.play()
    }, [])

    return (
        <NonFlexingContainer
            className="video-aspect-ratio"
            innerRef={containerRef}
            position="relative"
            marginBottom={shouldAddMarginBottom ? 'spacing-6' : 'spacing-none'}
        >
            <div
                style={{
                    display: isVideoTouched ? 'block' : 'none',
                }}
            >
                {dayjs(endTime).add(10, 'minute').isBefore(dayjs()) ? (
                    <NonFlexingContainer
                        className="video-aspect-ratio"
                        backgroundColor="onyx"
                        center
                    >
                        <NonFlexingContainer marginBottom="spacing-6">
                            <Sport1Logo width={150} />
                        </NonFlexingContainer>
                        <Font
                            fontVariant="Body-L"
                            fontFamilyVariant="Sport1-Regular"
                            nextFontFamilyVariant={sport1Regular.style.fontFamily}
                            color="moon-2"
                        >
                            Dieser Livestream ist beendet.
                        </Font>
                    </NonFlexingContainer>
                ) : (
                    jwPlayer
                )}
            </div>
            {!isVideoTouched ? (
                <TVStreamTeaser
                    labels={
                        label
                            ? [{ labelType: label === 'BREAKING' ? 'breaking' : 'live' }]
                            : undefined
                    }
                    buttonIcon="play-active"
                    headlineText={title}
                    subHeadlineText={subTitle}
                    webHeadlineType="h2"
                    buttonText={!hidePlayButton ? 'Abspielen' : undefined}
                    mainImageUrl={transformImageUrlToSize(imageUrl, 900, 505)}
                    mainImageAlt={title}
                    onPress={onPlay}
                />
            ) : null}
        </NonFlexingContainer>
    )
}

export default LivestreamPlayer
