import React, { FC, memo, useCallback, useRef } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { DashboardPresentingProps } from '@sport1/types/web'
import Image from '@sport1/news-styleguide/Image'
import Touchable from '@sport1/news-styleguide/Touchable'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import useScreenType from '@/hooks/useScreenType'
import { mapTrackingData, useTracking } from '@/utils/tracking/TrackingProvider'
import isSport1 from '@/utils/url/isSport1'

interface Props extends SystemSpacingsProps {
    dashboardPresenting: DashboardPresentingProps
}

const DashboardPresenting: FC<Props> = ({ dashboardPresenting, marginBottom, marginTop }) => {
    const { trackCampaign } = useTracking()
    const initial = useRef(true)
    const ref = useRef<HTMLDivElement>(null)
    const { isMobileDevice } = useScreenType()

    const track = useCallback(
        (event: string): void => {
            const { meta, targetPlatform } = dashboardPresenting

            if (
                (!isMobileDevice() && targetPlatform === 'mobile') ||
                (isMobileDevice() && targetPlatform === 'web')
            ) {
                return
            }

            trackCampaign({
                event,
                campaign: 'ad',
                target_url: dashboardPresenting.url,
                additionalTrackingData: mapTrackingData(meta?.tracking),
            })
        },
        [dashboardPresenting, trackCampaign, isMobileDevice]
    )

    const onPress = useCallback(() => track('ad.click'), [track])

    React.useEffect(() => {
        if (initial.current) {
            initial.current = false
            track('ad.impression')
        }
    }, [track])

    const follow = isSport1(dashboardPresenting.url) ? 'follow' : 'nofollow'
    return (
        <NonFlexingContainer
            innerRef={ref}
            display={
                dashboardPresenting.targetPlatform === 'mobile'
                    ? ['flex', 'flex', 'none']
                    : ['none', 'none', 'flex']
            }
            marginBottom={marginBottom}
            marginTop={marginTop}
        >
            <Touchable
                href={dashboardPresenting.url}
                onPress={onPress}
                webTarget="_blank"
                webRel={() => follow}
            >
                <Image
                    size="L"
                    alt={dashboardPresenting.name}
                    aspectRatio="ORIGINAL"
                    imageUrl={dashboardPresenting.imageUrl}
                />
            </Touchable>
        </NonFlexingContainer>
    )
}

export default memo(DashboardPresenting)
