import { AdConfigDataProps } from '@sport1/types/web'
import { AdMidrollJsonProps } from '@/components/Ads/types/AdsTypes'
import { IJWPlayer } from '@/types/jwplayer'
import Config from '@/utils/Config'
import hasStorage from '@/utils/storage/hasStorage'
import { getVastUrl } from '@/utils/ads/vastConfig'
import { isPureActive } from '@/components/ThirdParty/Piano/util'
import { readCookies, writeCookie } from '@/utils/cookie'
import createUUID from '@/utils/id/createUUID'
import { VideoZoneType } from '@/types/vastAd'

/** Function to play midrolls from SOM and mediaImpact
 *  there are few sessionStorage parameter:
 *  - adScheduleInterval: default 10000, can be reset to a longer or shorter Period
 *  - adComment: default false, when activated you can see the working mechanics
 */

declare global {
    interface Window {
        S1MidrollsFunctions: {
            resetAdStatus: Function
            resetLogging: Function
            setAdStatus: Function
            setLoggingStatus: Function
            showLoggingTypes: Function
        }
    }
}

type statusProps = {
    currentMidrollIndex: number
    events: Array<AdMidrollJsonProps>
    initialized: boolean
    midrollIndex: number
    prerollRuns: boolean
    runMidrolls: boolean
}

type configProps = {
    readonly AD_SCHEDULE_INTERVAL: number
    readonly api: string
    readonly FAKE_SERVER_RESPONSE_KEY: string
    readonly initTimeOut: number
    readonly MAX_AGE_ALLOWED: number
    showMessage: LoggingType | undefined
    readonly blockDuration: number
    readonly isStroer: boolean
    blockIndex: number
    blockIndexNeedsUpdate: boolean
    cmpConsent: boolean | undefined
    vastTag: string
    adRequestedWithNoConsent: boolean
}

enum LoggingType {
    ALL = 'all',
    ADS = 'ads',
    EVENT = 'event',
    INIT = 'init',
    INTERVAL_ADS = 'interval_ads',
    INTERVAL_SCHEDULE = 'interval_schedule',
}

const midrolls = (
    player: IJWPlayer,
    title: string,
    videoUrl: string,
    videozone: VideoZoneType,
    videoAds: AdConfigDataProps | undefined
): void => {
    const incrementBlockIndexInVastTag = () => {
        writeConsoleLogs(LoggingType.ADS, 'incrementBlockIndexInVastTag 1', config.vastTag)
        const nextIndex = config.blockIndex + 1
        config.vastTag = config.vastTag.replace(
            `block%5Bindex%5D=${config.blockIndex.toString()}`,
            `block%5Bindex%5D=${nextIndex}`
        )
        config.blockIndex = nextIndex
        writeConsoleLogs(LoggingType.ADS, 'incrementBlockIndexInVastTag 2', config.vastTag)
    }

    const createVastUrl = (ppid: string | undefined, gdprConsentString: string | undefined) => {
        writeConsoleLogs(LoggingType.INIT, 'createVastUrl 1', ppid, gdprConsentString)
        let vastUrl =
            'https://mandates.somquery.sqrt-5041.de/v4/ad-pod/5cbc010d-0f7f-42be-82b6-4619eec40a86?'
        const appendKeyValue = (key: string, value?: string, append = true) => {
            if (value) {
                vastUrl += `${append ? '&' : ''}${encodeURIComponent(key)}=${value}`
            }
        }
        appendKeyValue('block[duration]', String(config.blockDuration), false)
        appendKeyValue('block[index]', String(config.blockIndex))
        appendKeyValue('block[name]', 'midcontent')
        appendKeyValue('client[country]', 'de')
        appendKeyValue('client[dc]', 'web')
        appendKeyValue('client[version]', encodeURIComponent(`web-${Config.RELEASE}`))
        appendKeyValue('consent[gdprConsent]', gdprConsentString)
        appendKeyValue('iabContent[id]', '591499')
        appendKeyValue('iabContent[livestream]', '1')
        appendKeyValue('iabContent[title]', encodeURIComponent(title))
        appendKeyValue('iabContent[url]', encodeURIComponent(videoUrl))
        appendKeyValue('ids[ppid]', ppid)
        appendKeyValue('inventory[brand]', 'sport1')
        appendKeyValue('inventory[category]', 'sport')
        appendKeyValue('inventory[category]', 'live')
        appendKeyValue('inventory[site]', 'sport1')
        appendKeyValue('synd[aid]', 'INV-MAP-1')

        writeConsoleLogs(LoggingType.INIT, 'createVastUrl 2', vastUrl)
        return vastUrl
    }

    const createVastTag = () => {
        const cookieValues = readCookies(['euconsent-v2', 'ppid'])
        const gdprConsentString = cookieValues?.['euconsent-v2'] || undefined
        let ppid = cookieValues?.['ppid'] || undefined
        writeConsoleLogs(LoggingType.INIT, 'createVastTag 1', gdprConsentString, ppid)

        // gdprConsentString was read but is not relevant for the writing of the ppid
        if (config.cmpConsent && ppid === undefined) {
            ppid = createUUID()
            writeConsoleLogs(LoggingType.INIT, 'createVastTag 1 writePpid', ppid)
            writeCookie({ name: 'ppid', value: ppid, expireDate: { value: 60, unit: 'day' } })
        }
        return createVastUrl(ppid, gdprConsentString)
    }

    const config: configProps = {
        AD_SCHEDULE_INTERVAL:
            (hasStorage() && Number(window.sessionStorage.adScheduleInterval)) || 10000,
        api: `${Config.CMS_API}/v3/de/midroll`,
        FAKE_SERVER_RESPONSE_KEY: 's1-midroll-fake-response',
        initTimeOut: 500,
        isStroer: Config.MIDROLLS_AD_PROVIDER === 'STROER',
        MAX_AGE_ALLOWED: 10 * 60 * 1000,
        showMessage: (hasStorage() && window.sessionStorage.adComment) || undefined,
        vastTag: '',
        blockDuration: 200,
        blockIndex: 1,
        blockIndexNeedsUpdate: false,
        cmpConsent: true,
        adRequestedWithNoConsent: false,
    }

    const status: statusProps = {
        currentMidrollIndex: -1,
        events: [],
        initialized: false,
        midrollIndex: 0,
        prerollRuns: false,
        runMidrolls: false,
    }

    const schedule = () => {
        writeConsoleLogs(LoggingType.INTERVAL_SCHEDULE, 'schedule 1', config.api)
        fetch(config.api)
            .then((res): Promise<AdMidrollJsonProps> => res.json())
            .then(data => {
                let workData = data

                if (
                    hasStorage() &&
                    window.sessionStorage.getItem(config.FAKE_SERVER_RESPONSE_KEY)
                ) {
                    workData = JSON.parse(
                        window.sessionStorage.getItem(config.FAKE_SERVER_RESPONSE_KEY) || ''
                    )
                }
                const lastUpdate = new Date(workData.time * 1000)

                writeConsoleLogs(
                    LoggingType.INTERVAL_SCHEDULE,
                    'schedule 2',
                    lastUpdate,
                    workData,
                    config.MAX_AGE_ALLOWED,
                    Date.now() - lastUpdate.getTime() > config.MAX_AGE_ALLOWED
                )
                if (Date.now() - lastUpdate.getTime() > config.MAX_AGE_ALLOWED) {
                    status.runMidrolls = false
                    return
                }

                const isEventNew =
                    0 ===
                    status.events.filter(event => {
                        return event.id === workData.id
                    }).length

                status.runMidrolls = 'ad_start' === workData.event

                if (isEventNew) {
                    status.events.push(workData)
                    status.currentMidrollIndex = -1
                    status.midrollIndex = 0
                }
            })
            .catch(() => {
                status.runMidrolls = false
            })
    }

    const playAds = (vastUrl: string | undefined) => {
        writeConsoleLogs(LoggingType.ADS, 'playAds 1 timeout', vastUrl)
        if (vastUrl) {
            player.playlistItem(1)
            setTimeout(() => {
                writeConsoleLogs(LoggingType.ADS, 'playAds 2 timeout', vastUrl)
                player.playlistItem(0)
                setTimeout(() => {
                    player && vastUrl && player.playAd(vastUrl)
                }, 1)
            }, 5000)
        }
    }

    const callAndPlayAds = (): void => {
        !config.isStroer
            ? playAds(config.vastTag)
            : getVastUrl({
                  ad: { ...videoAds, midrollEnabled: true },
                  videozone,
                  callback: tags => {
                      writeConsoleLogs(LoggingType.ADS, 'callAndPlayAds 1', tags)
                      playAds(tags?.midroll)
                  },
              })
    }

    const triggerAds = () => {
        writeConsoleLogs(LoggingType.INTERVAL_ADS, 'triggerAds 1', player.getState())
        if ('playing' !== player.getState()) {
            return
        }
        writeConsoleLogs(
            LoggingType.INTERVAL_ADS,
            'triggerAds 2',
            status.runMidrolls,
            status.prerollRuns
        )
        if (!status.runMidrolls || status.prerollRuns) {
            return
        }

        writeConsoleLogs(
            LoggingType.INTERVAL_ADS,
            'triggerAds 3',
            status.currentMidrollIndex,
            status.midrollIndex,
            config.adRequestedWithNoConsent,
            config.cmpConsent
        )
        if (status.currentMidrollIndex !== status.midrollIndex) {
            status.currentMidrollIndex = status.midrollIndex
            if (!config.adRequestedWithNoConsent) {
                if (!config.cmpConsent) {
                    config.adRequestedWithNoConsent = true
                }
                if (config.blockIndexNeedsUpdate) {
                    incrementBlockIndexInVastTag()
                }
                config.blockIndexNeedsUpdate = true
                callAndPlayAds()
            }
        }
    }

    const init = () => {
        writeConsoleLogs(LoggingType.INIT, 'init 1', status.initialized)
        if (status.initialized) {
            return
        }

        const onAdBreakEnd = (info: { readonly adposition?: string }): void => {
            if (info.adposition === 'pre') {
                status.runMidrolls = false
            }
            writeConsoleLogs(LoggingType.EVENT, 'onAdBreakEnd', info, status)
        }

        const adTime = function (info: { readonly adposition?: string }) {
            status.prerollRuns = info.adposition === 'pre'

            // if we are in prerolls, the currentMidrollIndex is not raised so it has the value -1
            if (!status.runMidrolls && status.currentMidrollIndex !== -1) {
                writeConsoleLogs(LoggingType.EVENT, 'adTime skipAd', status)
                player?.skipAd()
            }
            writeConsoleLogs(LoggingType.EVENT, 'adTime', info)
        }

        const adComplete = (info: { readonly adposition?: string }) => {
            status.prerollRuns = false

            writeConsoleLogs(
                LoggingType.EVENT,
                'adComplete',
                info,
                status.runMidrolls,
                info.adposition !== 'pre',
                status.runMidrolls && info.adposition !== 'pre'
            )
            if (!status.runMidrolls && info.adposition !== 'pre') {
                player.playlistItem(0)
            }
        }

        player.on('adBreakEnd', onAdBreakEnd)
        player.on('adTime', adTime)
        player.on('adComplete', adComplete)
        player.on('adError', (info: { adposition?: string }) => {
            writeConsoleLogs(LoggingType.EVENT, 'adError', info)
            if (status.runMidrolls) {
                adComplete({ ...info, adposition: status.runMidrolls ? 'mid' : undefined })
            }
        })

        const scheduleInterval = setInterval(schedule, config.AD_SCHEDULE_INTERVAL)
        const triggerInterval = setInterval(triggerAds, 1e3)
        writeConsoleLogs(LoggingType.INIT, 'init 2', scheduleInterval, triggerInterval)

        status.initialized = true
    }

    /**
     * Activate the current Midroll Logic
     * For debugging use only
     * @param isActive
     */
    const setAdStatus = (isActive: boolean) => {
        const time = Math.floor(new Date().getTime() / 1000)

        window.sessionStorage.setItem(
            config.FAKE_SERVER_RESPONSE_KEY,
            JSON.stringify({
                event: isActive ? 'ad_start' : 'ad_stop',
                id: time,
                time,
            })
        )
    }

    /**
     * Setting the Ads Midroll Logic to default
     * Remove debugging session of setAdStatus
     */
    const resetAdStatus = () => {
        if (hasStorage()) {
            window.sessionStorage.removeItem(config.FAKE_SERVER_RESPONSE_KEY)
        }
    }

    const setLoggingStatus = (type: LoggingType | undefined) => {
        config.showMessage = type
        if (hasStorage() && type) {
            window.sessionStorage.setItem('adComment', type)
        }
    }

    const showLoggingTypes = () =>
        Object.keys(LoggingType).map(key => (LoggingType as { [p: string]: string })[key])

    const resetLogging = () => {
        config.showMessage = undefined
        hasStorage() && window.sessionStorage.removeItem('adComment')
    }

    // eslint-disable-next-line
    const writeConsoleLogs = (type: LoggingType, message?: string, ...optionalParams: any[]) => {
        if (config.showMessage === LoggingType.ALL || config.showMessage === type) {
            // eslint-disable-next-line no-console
            console.log(`Midrolls ${message}`, optionalParams)
        }
    }

    const initFnc = () => {
        writeConsoleLogs(
            LoggingType.INIT,
            'initFnc 1 storage',
            hasStorage() && window.sessionStorage.consentInfo,
            window.S1MidrollsFunctions?.resetAdStatus
        )

        if (typeof window.S1MidrollsFunctions?.resetAdStatus === 'function') {
            return
        }

        window.S1MidrollsFunctions = {
            resetAdStatus,
            resetLogging,
            setAdStatus,
            setLoggingStatus,
            showLoggingTypes,
        }

        const consentInfo =
            hasStorage() && window.sessionStorage.consentInfo
                ? JSON.parse(window.sessionStorage.consentInfo)
                : []

        writeConsoleLogs(LoggingType.INIT, 'initFnc 2 consent', consentInfo['consent_info'])
        if (consentInfo['consent_info'] === 'on') {
            config.cmpConsent = !!consentInfo['cmp_som']
            config.vastTag = config.isStroer ? '' : createVastTag()
            init()
        } else {
            writeConsoleLogs(LoggingType.INIT, 'initFnc timeout', config.initTimeOut)
            setTimeout(initFnc, config.initTimeOut)
        }
    }
    writeConsoleLogs(LoggingType.INIT, 'initFnc startcall')
    // start call for init Function
    if (Config.ADS_ACTIVE && !isPureActive()) {
        initFnc()
    }

    // to get a plain slate
    resetAdStatus()
}

export default midrolls
