import { ComponentType } from '@sport1/types/web'
import { Type } from '@/types/video'
import { MappedTrackingData } from '@/utils/tracking/TrackingProvider'

type Props = {
    autostart: boolean
    trackingData?: MappedTrackingData
    type?: Type
    videoUrl?: string
}

export default function normalizeVideoTrackingData({
    autostart,
    trackingData,
    type,
    videoUrl,
}: Props): MappedTrackingData | undefined {
    if (!trackingData) {
        return undefined
    }

    trackingData.page_withvideo = 'true'
    trackingData.page_type = 'video'
    trackingData.format = autostart ? 'autoplay' : 'manual'

    if (!trackingData.video_type && type) {
        trackingData.video_type = type.toLowerCase()
    }

    if (!trackingData.video_url && videoUrl) {
        trackingData.video_url = videoUrl
    }

    /**
     * livestreams shall track with the same values, no matter if
     * included on tv.sport1.de or www.sport1.de
     */
    if (type === 'liveStream') {
        trackingData.component_type = ComponentType.LIVESTREAM_TEASER
        trackingData.interaction_label = 'livestream/sport1'
        trackingData.page_adLevel1 = 'livestream'
        trackingData.page_adLevel2 = '24_7'
        trackingData.page_all_tags = 'Video & TV'
        trackingData.page_ivw_code = 'vx_sport1'
        trackingData.page_name = 'livestream/sport1'
        trackingData.page_slug = '/tv-video'
        trackingData.url = 'livestream/sport1'
    }

    /**
     * eventstreams shall track with the same values, no matter where
     * included
     */
    if (type === 'eventStream') {
        trackingData.component_type = ComponentType.EDITORIAL_STREAM_TEASER
        trackingData.page_adLevel1 = 'tvvideo'
        trackingData.page_adLevel2 = 'event'
        trackingData.page_all_tags = 'Video & TV'
        trackingData.page_ivw_code = 'vx_eventstream'

        const url = trackingData.url?.replace('sport1://', '')
        trackingData.url = trackingData.interaction_label = trackingData.page_name = url
    }

    return trackingData
}
