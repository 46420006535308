const createUUID = () => {
    try {
        return crypto.randomUUID()
    } catch (ignore) {
        return '00000000-0000-0000-0000-000000000000'.replace(/0/g, () => {
            return Math.round(Math.random() * 15) // NOSONAR: we tried our best before
                .toString(16)
                .toLowerCase()
        })
    }
}

export default createUUID
