export const transformImageUrlToSize = (url: string, width: number, height: number): string =>
    url?.replace(`:width`, `${width}`).replace(`:height`, `${height}`)

export const defaultAdditionalWidths = [768, 384]

export const transformImageUrlToSrcSet = (
    url: string,
    width: number,
    height: number,
    additionalWidths: number[] = defaultAdditionalWidths //allows caller to specify custom additional widths
): string => {
    const ratio = width / height
    let srcSet = `${transformImageUrlToSize(url, width, height)} 1024w`

    additionalWidths.forEach(aw => {
        if (width > aw) {
            srcSet = `${transformImageUrlToSize(url, aw, Math.floor(aw / ratio))} ${aw}w,${srcSet}`
        }
    })

    return srcSet
}
